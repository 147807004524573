<template>
  <div class="app-container">
    <el-row :gutter="4" type="flex" justify="center">
      <el-col :span="20">
        <el-card class="box-card">
          <div slot="header" class="clearfix" justify="center" align="center">
            <strong><span>Subir Imagen</span></strong>
          </div>
          <div class="text item">
            <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
                <el-row :gutter="10" justify="space-around" align="middle">
                  <el-col :sm="24" :md="12" :lg="12" align="middle">
                    <el-form-item label="Agregar Imagen">
                      <el-upload
                        :on-change="onFileChange" style="margin-top: 1em;"
                        v-loading="loadingImage"
                        :show-file-list="false"
                        action="#"
                        list-type="picture-card"
                        :http-request="postImage"
                        :before-upload="beforeImageUpload"
                        :auto-upload="false"
                        ref="upload">
                        <div v-if="form.image !== null">
                          <img class="el-upload-list__item-thumbnail" :src="`${form.image}`" alt=""
                            style="width: 150px; height: 150px">
                        </div>
                        <template v-else>
                          <i slot="default" class="el-icon-plus"></i>
                        </template>
                      </el-upload>
                    </el-form-item>
                    <div>
                      <el-row :gutter="5" justify="center" align="middle">
                        <el-col :span="12" align="center">
                          <el-form-item label="Clasificación">
                            <el-input placeholder="Please input" v-model="form.folder" class="input-with-select">
                            </el-input>
                          </el-form-item>
                        </el-col>
                        <!--<el-col :span="12" align="center">
                          <el-form-item label="¿Imagen de Agradecimiento?">
                            <el-switch v-model="form.metadata.randomSelect" active-text="Si" inactive-text="No">
                            </el-switch>
                          </el-form-item>
                        </el-col>-->
                      </el-row>
                    </div>
                  </el-col>
                  <el-col :sm="24" :md="12" :lg="12">
                    <el-form-item label="Título">
                      <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                        <el-input size="mini" v-model="form.title"></el-input>
                        <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <el-form-item label="Descripción">
                      <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                        <el-input type="textarea" :rows="4" v-model="form.description"
                          :autosize="{ minRows: 1, maxRows: 4 }" maxlength="100" show-word-limit></el-input>
                        <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </el-form-item>
                    <!----
                  <el-row :gutter="5" justify="center" align="middle">
                    <el-col :span="12" align="center">
                      <el-form-item label="Temporal">
                        <el-switch
                          v-model="form.isTemp"
                          active-text="Si"
                          inactive-text="No">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" align="center">
                      <el-form-item label="Público">
                        <el-switch
                          v-model="form.isPublic"
                          active-text="Si"
                          inactive-text="No">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  ---->
                    <el-tag :key="tag" v-for="tag in form.metadata.categories"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag)">
                      {{ tag }}
                    </el-tag>
                    <el-input class="input-new-tag" v-if="inputVisible"
                      v-model="inputCategory"
                      ref="saveTagInput"
                      size="mini"
                      @keyup.enter.native="handleInputConfirm"
                      @blur="handleInputConfirm">
                    </el-input>
                    <el-button v-else class="button-new-tag" size="small" @click="showInput">+ Agregar Categoría</el-button>
                  </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                  <el-col :xs="8" :md="4" align="middle">
                    <el-form-item>
                      <el-button @click.stop.prevent="handlerCreate" type="primary" icon="el-icon-upload2" :disabled="invalid">
                        Agregar
                      </el-button>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="8" :md="4" align="middle">
                    <el-form-item>
                      <el-button @click.stop.prevent="handlerCancel" type="danger">
                        Cancelar
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </ValidationObserver>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { uploadImageFile } from '@/api/image.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'AddImage',
  data () {
    return {
      host: urlServer,
      form: {
        title: '',
        description: '',
        image: null,
        isTemp: false,
        isPublic: true,
        folder: 'images',
        metadata: {
          randomSelect: false,
          categories: []
        }
      },
      inputCategory: '',
      inputVisible: false,
      loadingImage: false,
      imageSelected: false,
      fileSelected: false
    }
  },
  mounted () {
  },
  methods: {
    handleClose (tag) {
      this.form.metadata.categories.splice(this.form.metadata.categories.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputCategory
      if (inputValue) {
        this.form.metadata.categories.push(inputValue)
      }
      this.inputVisible = false
      this.inputCategory = ''
    },
    handlerCancel () {
      this.$router.push('/gallery')
    },
    async handlerCreate (e) {
      e.preventDefault()
      console.log('entro al create')
      this.$refs.upload.submit()
      this.$router.push('/gallery')
    },
    onFileChange (file, fileList) {
      this.form.image = file.url
      console.log(file)
      console.log(fileList)
    },
    // --- Upload of Images -----
    beforeImageUpload (file) {
      const LENGTH_IMG = 2000
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2000K = file.size / 1024 < LENGTH_IMG

      if (!isJPG && !isPNG) {
        this.$message.error('La imagen debe estar en formato JPG o PNG!')
        return false
      }
      if (!isLt2000K) {
        this.$message.error('La imagen excede los 900kB!')
        return false
      }
      return true
    },
    async postImage (param) {
      // this.loading = true
      const metadata = {
        randomSelect: this.form.metadata.randomSelect,
        categories: this.form.metadata.categories
      }
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('title', `${this.form.title}`)
      formData.append('folder', `${this.form.folder}`)
      formData.append('isPublic', `${this.form.isPublic}`)
      formData.append('isTemp', `${this.form.isTemp}`)
      formData.append('alt', `${this.form.description !== '' ? this.form.description : 'Imagen de Probosque'}`)
      formData.append('metadata', JSON.stringify(metadata))
      // formData.append('folder', 'TO_BE_DEFINED')
      console.log('=====' + formData)
      uploadImageFile(formData)
        .then((response) => {
          this.imageSelected = true
          this.$message({
            showClose: true,
            message: 'Imagen cargada correctamente',
            type: 'success'
          })
        })
        .catch(response => {
          console.log(response)
          param.onError()
          this.$message.error('Ocurrio un error durante la carga')
        })
      // .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped>
.size-form-item>.el-form-item {
  margin: 0;
}

.size-form-item>.el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}

.my-autocomplete {
  width: 500px;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>
